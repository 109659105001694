<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生客户分析</a-breadcrumb-item>
            <a-breadcrumb-item>客户存量分析</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-body students">
            <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
                <a-tab-pane key="1">
                    <span slot="tab">渠道存量</span>
                    <channel v-if="activeKey === '1'" />
                </a-tab-pane>
                <a-tab-pane key="2">
                    <span slot="tab">员工存量</span>
                    <employee v-if="activeKey === '2'" />
                </a-tab-pane>
                <a-tab-pane key="3">
                    <span slot="tab">校区存量</span>
                    <studio v-if="activeKey === '3'" />
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import studio from './studio'
    import channel from './channel'
    import employee from './employee'
    export default {
        name:"customerStock",
        components: {
            channel,
            studio,
            employee,
        },
        data() {
            return {
                activeKey: '1'
            }
        },
        methods: {
            changeTab(key) {
                this.activeKey = key
            }
        },
    }
</script>